/*
 *
 * AppMain reducer
 *
 */
import produce from 'immer';
import { combineReducers } from 'redux';
import parse from 'html-react-parser';
import { flattenCategories, reorder } from 'utils/helpers';
import { ROLES } from 'utils/constants';
import { CHANGE_CATEGORY_ORDER } from '../admin/AdminCategoriesPage/constants';
import { CHANGE_TAG_ORDER } from '../admin/AdminTagsPage/constants';
import {
  LOAD_GLOBAL_INFO,
  LOAD_GLOBAL_INFO_ERROR,
  LOAD_GLOBAL_INFO_SUCCESS,
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_ERROR,
  LOAD_CATEGORIES_SUCCESS,
  SET_EDIT_MODE,
  SET_TAG,
  TOGGLE_SEARCH,
  LOAD_CURRENT_USER_SUCCESS,
  LOAD_CURRENT_USER_ERROR,
  SHOW_TOOLTIP,
  HIDE_TOOLTIP,
  TOOLTIP_LOADED,
  SET_PREVIEW_MODE,
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  loaded: false,
  data: {},
  currentTag: null,
  tagsBySlug: {},
  relationsBySlug: {},
  editMode: false,
  currentUser: null,
  userLoaded: false,
  isAdmin: false,
  searchOpen: false,
  token: null,
  roles: [],
  isPreview: false,
};

/* eslint-disable default-case, no-param-reassign */
const globalReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_GLOBAL_INFO:
        if (!action.reloadOnly) {
          draft.loading = true;
        }
        draft.error = false;
        break;
      case LOAD_GLOBAL_INFO_SUCCESS:
        draft.loading = false;
        draft.loaded = true;
        draft.data = action.payload;
        // draft.currentUser = action.payload.user;
        draft.tagsBySlug = action.payload.tags.reduce((acc, val) => {
          acc[val.slug] = val;
          return acc;
        }, {});
        if (action.payload.relations) {
          draft.relationsBySlug = action.payload.relations.reduce(
            (acc, val) => {
              acc[val.slug] = val;
              return acc;
            },
            {},
          );
        }
        draft.tags = action.payload.tags;
        break;
      case LOAD_GLOBAL_INFO_ERROR:
        draft.loading = false;
        draft.error = true;
        break;
      case SET_TAG:
        // eslint-disable-next-line no-case-declarations
        draft.currentTag = state.tagsBySlug[action.payload];
        break;
      case SET_EDIT_MODE:
        draft.editMode = action.payload;
        break;
      case TOGGLE_SEARCH:
        draft.searchOpen = action.payload;
        break;
      case LOAD_CURRENT_USER_SUCCESS:
        draft.currentUser = action.payload;
        draft.token = action.payload.token?.token;
        if (draft.token) {
          localStorage.setItem('jwt_token', draft.token);
        }
        draft.isAdmin =
          action.payload.capabilities &&
          action.payload.capabilities.indexOf('administrator') >= 0;
        draft.roles = action.payload.capabilities || [];
        draft.userLoaded = true;
        break;
      case LOAD_CURRENT_USER_ERROR:
        draft.userLoaded = true;
        draft.isAdmin = false;
        draft.currentUser = {};
        localStorage.removeItem('jwt_token');
        break;
      case CHANGE_TAG_ORDER:
        draft.tags = reorder(draft.tags, action.idx, action.idx2);
        break;
      case SET_PREVIEW_MODE:
        // TODO: capability CanViewPreview or something
        const preview =
          draft.currentUser &&
          (draft.roles.includes(ROLES.EMPLOYEE) ||
            draft.roles.includes(ROLES.ADMIN) ||
            draft.roles.includes(ROLES.COMMENT_ADMIN) ||
            draft.roles.includes(ROLES.AUTHOR))
            ? action.preview
            : false;
        draft.isPreview = preview;
        // TODO: move to saga?
        if (!preview) {
          sessionStorage.removeItem('preview');
        }
        break;
    }
  });

/* export const tagsInitialState = {
  data: [],
  dataBySlug: {},
  currentTag: null,
}; */

export const categoryInitialState = {
  loading: false,
  error: false,
  data: [],
  dataBySlug: {},
  currentCategory: null,
};

const categoriesReducer = (state = categoryInitialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_CATEGORIES:
        if (!action.reloadOnly) {
          draft.loading = true;
        }
        draft.error = false;
        break;
      case LOAD_CATEGORIES_SUCCESS:
        draft.data = action.payload.map(c => ({ ...c, title: parse(c.title) }));
        draft.dataBySlug = flattenCategories(draft.data).reduce((acc, val) => {
          acc[val.slug] = val;
          return acc;
        }, {});
        draft.loading = false;
        break;
      case LOAD_CATEGORIES_ERROR:
        draft.loading = false;
        draft.error = true;
        break;
      case CHANGE_CATEGORY_ORDER:
        console.log(action);
        if (action.parent) {
          const parentCat = Object.values(draft.dataBySlug).find(
            c => c.id === action.parent,
          );
          parentCat.childrens = reorder(
            parentCat.childrens,
            action.idx,
            action.idx2,
          );
          const idx = draft.data.findIndex(c => c.id === action.parent);
          draft.data[idx] = parentCat;
          draft.dataBySlug = flattenCategories(draft.data).reduce(
            (acc, val) => {
              acc[val.slug] = val;
              return acc;
            },
            {},
          );
        } else {
          draft.data = reorder(draft.data, action.idx, action.idx2);
          draft.dataBySlug = flattenCategories(draft.data).reduce(
            (acc, val) => {
              acc[val.slug] = val;
              return acc;
            },
            {},
          );
        }
        break;
    }
  });

export const tooltipInitialState = {
  loading: false,
  error: false,
  loaded: false,
  data: false,
  related: null,
  currentSlug: null,
  x: 0,
  y: 0,
};

/* eslint-disable default-case, no-param-reassign */
const tooltipReducer = (state = tooltipInitialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SHOW_TOOLTIP:
        draft.loading = true;
        draft.error = false;
        draft.currentSlug = action.slug;
        draft.x = action.x;
        draft.y = action.y;
        break;
      case TOOLTIP_LOADED:
        draft.data = action.data;
        draft.related = action.related;
        draft.loaded = true;
        draft.loading = false;
        break;
      case HIDE_TOOLTIP:
        draft.currentSlug = null;
        draft.data = false;
        draft.related = null;
        draft.loaded = false;
    }
  });

export default combineReducers({
  global: globalReducer,
  categories: categoriesReducer,
  tooltips: tooltipReducer,
});
